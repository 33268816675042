import { Convert } from '../../helpers/convert';

export enum UrlParameterFor {
  None = 0,
  ConfirmAccount = 1,
  ResetPassword = 2,
  RequestToJoinTeam = 3,
}

export class ConfirmUserModel {
  username?: string;
  code?: string;
  type?: number;

  constructor(model: ConfirmUserModel) {
    this.username = model.username || '';
    this.code = model.code || '';
    this.type = model.type || 0;
  }
}

export class RequestToJoinTeamParamModel {
  playerId?: number;
  teamId?: number;
  notificationId?: number;

  constructor(model: RequestToJoinTeamParamModel) {
    if (typeof model.playerId === 'string') {
      this.playerId = Convert.toInt(model.playerId);
    } else {
      this.playerId = model.playerId || 0;
    }

    if (typeof model.teamId === 'string') {
      this.teamId = Convert.toInt(model.teamId);
    } else {
      this.teamId = model.teamId || 0;
    }

    if (typeof model.notificationId === 'string') {
      this.notificationId = Convert.toInt(model.notificationId);
    } else {
      this.notificationId = model.notificationId || 0;
    }
  }
}
