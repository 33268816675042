import { NetGross } from './enums/net-gross.enum';
import { InlinePosition, SelectItem } from './modules/fs-controls-shared.model';
import { FSButtonsGroup } from './modules/fs-controls/fs-buttons-group/fs-buttons-group.model';
import { FSChips } from './modules/fs-controls/fs-chips/fs-chips.model';
import {
  FSLayerSelector,
  FSLayerSelectorItem,
} from './modules/fs-controls/fs-layer-selector/fs-layer-selector.model';
import { FSRadioButtons } from './modules/fs-controls/fs-radio-buttons/fs-radio-buttons.model';
import {
  FsSelectorFieldDataSource,
  FsSelectorFieldValue,
} from './modules/fs-controls/fs-selector-field/fs-selector-field.model';
import { FSSimpleSelector } from './modules/fs-controls/fs-simple-selector/fs-simple-selector.model';
import {
  FSStackList,
  FSStackListItem,
  FSStackCell,
} from './modules/fs-controls/fs-stack-list/fs-stack-list.model';
import {
  FSTableHeader,
  FSTableHeaderCell,
} from './modules/fs-controls/fs-table/fs-table.model';
import { FSLeagueOption } from './modules/fs-custom-controls/fs-league-table/fs-league-table.model';

export const FirstNameMaxLength = 50;
export const LastNameMaxLength = 50;
export const EmailMaxLength = 100;
export const PasswordMaxLength = 36;
export const AutoRefreshDelay = 10000;

export const ScreenBreakpoint = 868;
export const InvalidIdMessage = 'invalid id';

export const InvalidCharacters = [
  '+',
  '-',
  '*',
  '/',
  '.',
  ',',
  '/',
  '\\',
  '}',
  '{',
  '&',
  '@',
  '!',
  '~',
  '$',
  '%',
  '^',
  '(',
  ')',
  '_',
  "'",
  '"',
  ':',
  ';',
  '#',
  '`',
  '?',
  ' ',
  '=',
];

export const HandicapRange = {
  min: 0,
  max: 36,
  default: 0,
};

export const GrossRange = {
  min: 1,
  max: 99,
  default: 1,
};

export const NetRange = {
  min: -2,
  max: 99,
  default: -2,
};

export const IgnoreAuthenticateUrl: Array<string> = ['/teams/join'];

//Navbar items
export const NavbarItemsData: Array<any> = [
  {
    id: 1,
    name: 'home',
    view: 'Home',
    route: 'home',
    children: [
      {
        id: 4,
        route: 'settings',
        name: 'settings',
        view: 'Settings',
      },
    ],
  },
  {
    id: 2,
    name: 'venues',
    view: 'Venues',
    route: 'venues',
    children: [
      {
        id: 3,
        route: 'event',
        name: 'event',
        view: 'Event',
      },
    ],
  },
];

//Net gross switcher
export const NetGrossData = new FsSelectorFieldDataSource({
  values: [
    new FsSelectorFieldValue({
      name: 'NET',
      value: NetGross.Net,
    }),
    new FsSelectorFieldValue({
      name: 'GROSS',
      value: NetGross.Gross,
    }),
  ],
});

export const GrossData = new FsSelectorFieldDataSource({
  values: [
    new FsSelectorFieldValue({
      name: 'GROSS',
      value: NetGross.Gross,
    }),
  ],
});

export const NetData = new FsSelectorFieldDataSource({
  values: [
    new FsSelectorFieldValue({
      name: 'NET',
      value: NetGross.Net,
    }),
  ],
});

export const NetGrossData2: FSButtonsGroup = new FSButtonsGroup({
  id: 1,
  buttonsSize: 70,
  hideNav: true,
  values: [
    new SelectItem({
      id: 1,
      name: 'NET',
      value: NetGross.Net,
    }),
    new SelectItem({
      id: 2,
      name: 'GROSS',
      value: NetGross.Gross,
    }),
  ],
});

//Add or delete venue card to favorite
export const AddFavoriteVenue = new FSChips({
  icon: './assets/icons/Star.svg',
  title: 'Add',
});

export const DeleteFavoriteVenue = new FSChips({
  icon: './assets/icons/Star-Red.svg',
  title: 'Del',
});
//--------------------------------------

//Simulator settings data
export const SimSettingsData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'PREFERENCES',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'CONDITIONS',
      value: 2,
    }),
  ],
});

export const TeeBoxData: FSLayerSelector = new FSLayerSelector({
  id: 1,
  label: 'Select To Continue',
  name: 'SimSettingsSelector',
  layerSize: 55,
  values: [
    new FSLayerSelectorItem({
      background: '#FFC108',
      id: 1,
      label: 'CH',
      color: '#000000',
    }),
    new FSLayerSelectorItem({
      background: '#000000',
      id: 2,
      label: 'BK',
      color: '#ffffff',
    }),
    new FSLayerSelectorItem({
      background: '#3369FF',
      id: 3,
      label: 'BL',
      color: '#ffffff',
    }),
    new FSLayerSelectorItem({
      background: '#FFFFFF',
      id: 4,
      label: 'WH',
      color: '#000000',
    }),
    new FSLayerSelectorItem({
      background: '#E51616',
      id: 5,
      label: 'RD',
      color: '#ffffff',
    }),
    new FSLayerSelectorItem({
      background: '#7ED321',
      id: 6,
      label: 'GR',
      color: '#000000',
    }),
  ],
});

export const getTeePositionName = (id: number): string => {
  switch (id) {
    case 1:
      return 'Championship';
    case 2:
      return 'Black';
    case 3:
      return 'Blue';
    case 4:
      return 'White';
    case 5:
      return 'Red';
    case 6:
      return 'Green';
    default:
      return '';
  }
};

export const MulliganData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'UNLTD',
      value: 1000,
    }),
    new FsSelectorFieldValue({
      name: 'OFF',
      value: 0,
    }),
    new FsSelectorFieldValue({
      name: '3',
      value: 3,
    }),
    new FsSelectorFieldValue({
      name: '5',
      value: 5,
    }),
    new FsSelectorFieldValue({
      name: '7',
      value: 7,
    }),
  ],
});

export const GimmiesData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: '8 FT',
      value: 3,
    }),
    new FsSelectorFieldValue({
      name: '10 FT',
      value: 4,
    }),
    new FsSelectorFieldValue({
      name: '6 FT',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'AUTO',
      value: 5,
    }),
  ],
});

export const TracerViewData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'SOLID',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'COMET',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'NONE',
      value: 3,
    }),
  ],
});

export const PlayerViewData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'BALL',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'CLASSIC',
      value: 1,
    }),
  ],
});

export const FullShotUnitsData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'YD',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'M',
      value: 2,
    }),
  ],
});

export const PuttingUnitData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'FT',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'CM',
      value: 2,
    }),
  ],
});

export const SoundFXData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'ON',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'OFF',
      value: 1,
    }),
  ],
});

export const PinPostionData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'EASY',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'MED',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'HARD',
      value: 3,
    }),
  ],
});

export const GreenSpeedData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'MED',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'SLOW',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'FAST',
      value: 3,
    }),
  ],
});

export const GreenFirmnessData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'NORM',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'SOFT',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'FIRM',
      value: 3,
    }),
  ],
});

export const PuttingGridData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'FADE',
      value: 3,
    }),
    new FsSelectorFieldValue({
      name: 'OFF',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'ON',
      value: 1,
    }),
  ],
});

export const PuttingArrowData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'FADE',
      value: 3,
    }),
    new FsSelectorFieldValue({
      name: 'OFF',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'ON',
      value: 1,
    }),
  ],
});

export const FairwayData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'NORM',
      value: 2,
    }),
    new FsSelectorFieldValue({
      name: 'SOFT',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'FIRM',
      value: 3,
    }),
  ],
});

export const WindData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'OFF',
      value: 3,
    }),
    new FsSelectorFieldValue({
      name: 'BREEZY',
      value: 1,
    }),
    new FsSelectorFieldValue({
      name: 'WINDY',
      value: 2,
    }),
  ],
});

export const CourseElevationData = new FsSelectorFieldDataSource<number>({
  values: [
    new FsSelectorFieldValue({
      name: 'COURSE',
      value: -1,
    }),
    new FsSelectorFieldValue({
      name: 'SEA LVL',
      value: 0,
    }),
    new FsSelectorFieldValue({
      name: 'CUSTOM',
      value: -2,
    }),
  ],
});
//--------------------------------------

//Update profiles data
export const HandinessSelectorData = new FSSimpleSelector({
  id: 1,
  label: 'Handedness',
  name: 'Handedness',
  values: [
    new SelectItem({
      id: 1,
      name: 'L',
      value: 'left',
      isActive: true,
    }),
    new SelectItem({
      id: 2,
      name: 'R',
      value: 'right',
      isActive: true,
    }),
  ],
});

export const GenderSelectorData = new FSSimpleSelector({
  id: 2,
  label: 'Gender',
  name: 'gender',
  values: [
    new SelectItem({
      id: 1,
      name: 'M',
      value: 'male',
      isActive: true,
    }),
    new SelectItem({
      id: 2,
      name: 'F',
      value: 'female',
      isActive: true,
    }),
  ],
});

export const HandicapSelectorData = new FSSimpleSelector({
  id: 3,
  label: 'Handicap',
  name: 'handicap',
  values: [
    new SelectItem({
      id: 1,
      value: '12',
      name: '12',
      isActive: true,
    }),
  ],
});
//--------------------------------------

//Tabs data
export const SettingsPageTabData: any[] = [
  {
    id: 1,
    name: 'Profile',
    activate: true,
  },
  {
    id: 2,
    name: 'Sim Settings',
  },
  {
    id: 3,
    name: 'My Venues',
  },
  {
    id: 4,
    name: 'My Events',
  },
  {
    id: 5,
    name: 'My Teams',
  },
  // {
  //   id: 6,
  //   name: 'My Stats',
  //   isDisable: true,
  // },
];
//--------------------------------------

//My events tab switch
export const MyEventsTabsData: Array<SelectItem> = [
  new SelectItem({
    id: 1,
    isActive: true,
    name: 'Current',
    value: 0,
  }),
  new SelectItem({
    id: 2,
    isActive: true,
    name: 'Completed',
    value: 1,
  }),
];
//---------------------------------------

//Tables data
export const EventTableHeaderData: FSTableHeader = new FSTableHeader({
  cells: [
    new FSTableHeaderCell({
      value: 'Event / Location',
      width: '28%',
    }),
    new FSTableHeaderCell({
      value: 'Round',
      inlinePosition: InlinePosition.Center,
      width: '50px',
    }),
    new FSTableHeaderCell({
      value: 'Starts',
      inlinePosition: InlinePosition.Center,
      width: '90px',
    }),
    new FSTableHeaderCell({
      value: 'Ends',
      inlinePosition: InlinePosition.Center,
      width: '90px',
    }),
    new FSTableHeaderCell({
      value: 'Max Plyrs',
      inlinePosition: InlinePosition.Center,
      width: '90px',
    }),
    new FSTableHeaderCell({
      value: 'Per Team',
      inlinePosition: InlinePosition.Center,
      width: '60px',
    }),
    new FSTableHeaderCell({
      value: 'Event Code',
      inlinePosition: InlinePosition.Center,
      width: '90px',
    }),
    new FSTableHeaderCell({
      width: '120px',
    }),
  ],
});

export const MyEventsTableHeaderData: FSTableHeader = new FSTableHeader({
  cells: [
    new FSTableHeaderCell({
      value: 'Per Team',
      inlinePosition: InlinePosition.Center,
      width: '60px',
    }),
    new FSTableHeaderCell({
      value: 'Date',
      inlinePosition: InlinePosition.Center,
      width: '100px',
    }),
    new FSTableHeaderCell({
      value: 'Event',
      width: '400px',
    }),
    new FSTableHeaderCell({
      value: 'Round',
      inlinePosition: InlinePosition.Center,
      width: '85px',
    }),
    new FSTableHeaderCell({
      value: 'Course',
      width: '300px',
    }),
    new FSTableHeaderCell({
      width: '250px',
    }),
  ],
});

export const EventDetailsTableHeaderData: FSTableHeader = new FSTableHeader({
  cells: [
    new FSTableHeaderCell({
      value: 'Event / Location',
      width: '32%',
    }),
    new FSTableHeaderCell({
      value: 'Round',
      inlinePosition: InlinePosition.Center,
      width: '50px',
    }),
    new FSTableHeaderCell({
      value: 'Starts',
      inlinePosition: InlinePosition.Center,
      width: '90px',
    }),
    new FSTableHeaderCell({
      value: 'Ends',
      inlinePosition: InlinePosition.Center,
      width: '90px',
    }),
    new FSTableHeaderCell({
      value: 'Max Plyrs',
      inlinePosition: InlinePosition.Center,
      width: '90px',
    }),
    new FSTableHeaderCell({
      value: 'Per Team',
      inlinePosition: InlinePosition.Center,
      width: '60px',
    }),
    new FSTableHeaderCell({
      value: 'Event Code',
      inlinePosition: InlinePosition.Center,
      width: '90px',
    }),
  ],
});

export const MyStatsHeaderData: FSTableHeader = new FSTableHeader({
  cells: [
    new FSTableHeaderCell({
      value: 'Per Team',
      width: '58px',
    }),
    new FSTableHeaderCell({
      value: 'Date',
      inlinePosition: InlinePosition.Center,
      width: '100px',
    }),
    new FSTableHeaderCell({
      value: 'Event',
      width: '400px',
    }),
    new FSTableHeaderCell({
      value: 'Total Score',
      width: '500px',
    }),
    new FSTableHeaderCell({
      width: '100px',
    }),
  ],
});

export const EventScorecardHeaderData: FSTableHeader = new FSTableHeader({
  cells: [
    new FSTableHeaderCell({
      value: 'Event',
      width: '400px',
    }),
    new FSTableHeaderCell({
      value: 'Starts',
      inlinePosition: InlinePosition.Center,
      width: '100px',
    }),
    new FSTableHeaderCell({
      value: 'Ends',
      inlinePosition: InlinePosition.Center,
      width: '100px',
    }),
    new FSTableHeaderCell({
      value: 'Players',
      inlinePosition: InlinePosition.Center,
      width: '100px',
    }),
    new FSTableHeaderCell({
      value: '# Per Group',
      inlinePosition: InlinePosition.Center,
      width: '100px',
    }),
    new FSTableHeaderCell({
      value: '# Of Groups',
      inlinePosition: InlinePosition.Center,
      width: '120px',
    }),
    new FSTableHeaderCell({
      width: '200px',
    }),
  ],
});

export const stackListData: FSStackList = new FSStackList({
  id: 1,
  items: [
    new FSStackListItem({
      label: 'Hole',
      name: 'hole',
      classes: 'fs-stack-list-item-bold',
      cells: [
        new FSStackCell({
          value: '1',
        }),
        new FSStackCell({
          value: '2',
        }),
        new FSStackCell({
          value: '3',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '6',
        }),
        new FSStackCell({
          value: '7',
        }),
        new FSStackCell({
          value: '8',
        }),
        new FSStackCell({
          value: '9',
        }),
        new FSStackCell({
          value: 'OUT',
        }),
        new FSStackCell({
          value: '10',
        }),
        new FSStackCell({
          value: '11',
        }),
        new FSStackCell({
          value: '12',
        }),
        new FSStackCell({
          value: '13',
        }),
        new FSStackCell({
          value: '14',
        }),
        new FSStackCell({
          value: '15',
        }),
        new FSStackCell({
          value: '16',
        }),
        new FSStackCell({
          value: '17',
        }),
        new FSStackCell({
          value: '18',
        }),
        new FSStackCell({
          value: 'IN',
        }),
        new FSStackCell({
          value: 'TOT',
        }),
      ],
    }),
    new FSStackListItem({
      label: "H'Cap",
      name: 'hcap',
      cells: [
        new FSStackCell({
          value: '15',
        }),
        new FSStackCell({
          value: '3',
        }),
        new FSStackCell({
          value: '13',
        }),
        new FSStackCell({
          value: '9',
        }),
        new FSStackCell({
          value: '7',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '1',
        }),
        new FSStackCell({
          value: '17',
        }),
        new FSStackCell({
          value: '11',
        }),
        new FSStackCell({
          value: ' ',
        }),
        new FSStackCell({
          value: '12',
        }),
        new FSStackCell({
          value: '16',
        }),
        new FSStackCell({
          value: '8',
        }),
        new FSStackCell({
          value: '2',
        }),
        new FSStackCell({
          value: '10',
        }),
        new FSStackCell({
          value: '18',
        }),
        new FSStackCell({
          value: '6',
        }),
        new FSStackCell({
          value: '4',
        }),
      ],
    }),
    new FSStackListItem({
      label: 'Par',
      name: 'par',
      classes: 'fs-stack-list-item-deactive',
      cells: [
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '3',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '3',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '36',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '3',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '3',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '37',
        }),
        new FSStackCell({
          value: '73',
        }),
      ],
    }),
    new FSStackListItem({
      label: 'Gross',
      name: 'gross',
      classes: 'fs-stack-list-item-bold',
      cells: [
        new FSStackCell({
          value: '3',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '3',
          classes: 'fs-stack-list-cell-2rc',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '4',
          classes: 'fs-stack-list-cell-bs',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '3',
          classes: 'fs-stack-list-cell-2rc',
        }),
        new FSStackCell({
          value: '4',
          classes: 'fs-stack-list-cell-2rc',
        }),
        new FSStackCell({
          value: '36',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '3',
          classes: 'fs-stack-list-cell-rc',
        }),
        new FSStackCell({
          value: '5',
          classes: 'fs-stack-list-cell-rc',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '3',
          classes: 'fs-stack-list-cell-2bs',
        }),
        new FSStackCell({
          value: '4',
          classes: 'fs-stack-list-cell-bs',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '37',
        }),
        new FSStackCell({
          value: '73',
        }),
      ],
    }),
    new FSStackListItem({
      label: 'Net',
      name: 'net',
      classes: 'fs-stack-list-item-bold',
      cells: [
        new FSStackCell({
          value: '3',
          classes: 'fs-stack-list-cell-rc',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '3',
          classes: 'fs-stack-list-cell-bs',
        }),
        new FSStackCell({
          value: '4',
          classes: 'fs-stack-list-cell-2rc',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '3',
        }),
        new FSStackCell({
          value: '4',
          classes: 'fs-stack-list-cell-2bs',
        }),
        new FSStackCell({
          value: '36',
          classes: 'fs-stack-list-cell-bs',
        }),
        new FSStackCell({
          value: '4',
          classes: 'fs-stack-list-cell-bs',
        }),
        new FSStackCell({
          value: '3',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '3',
        }),
        new FSStackCell({
          value: '4',
        }),
        new FSStackCell({
          value: '5',
        }),
        new FSStackCell({
          value: '37',
          classes: 'fs-stack-list-cell-rc',
        }),
        new FSStackCell({
          value: '73',
          classes: 'fs-stack-list-cell-2rc',
        }),
      ],
    }),
  ],
});

export const MyStatsDetailsHeaderData: FSTableHeader = new FSTableHeader({
  cells: [
    new FSTableHeaderCell({
      value: 'Date',
      inlinePosition: InlinePosition.Center,
      width: '88px',
    }),
    new FSTableHeaderCell({
      value: 'Event',
      width: '260px',
    }),
    new FSTableHeaderCell({
      value: 'Course',
      width: '220px',
    }),
    new FSTableHeaderCell({
      value: 'Holes',
      width: '70px',
    }),
    new FSTableHeaderCell({
      value: 'Place',
      width: '70px',
    }),
    new FSTableHeaderCell({
      width: '200px',
    }),
  ],
});

export const TeamPlayersTableData: FSLeagueOption = new FSLeagueOption({
  sizeOfColumns: ['50px', '416px', '36px'],
  title: 'Teams',
  width: '500px',
});

export const EventPlayersTableData: FSTableHeader = new FSTableHeader({
  cells: [
    new FSTableHeaderCell({
      value: '#',
      width: '30px',
      inlinePosition: InlinePosition.Center,
    }),
    new FSTableHeaderCell({
      value: 'Name',
      width: '340px',
    }),
    new FSTableHeaderCell({
      inlinePosition: InlinePosition.Center,
      width: '30px',
    }),
  ],
});

export const MyTeamsTableColumnSizeData: Array<string> = [
  '69px',
  '220px',
  '384px',
  '160px',
  '110px',
  '72px',
  '100px',
  '100px',
  '36px',
];

export const ScorecardGroupsTableData: FSLeagueOption = new FSLeagueOption({
  sizeOfColumns: ['46px', '235px', '18px', '34px', '34px', '51px', '763px'],
  width: '1190px',
  hidePlayersQuantity: true,
});
//--------------------------------------

//Player per team radio buttons
export const PlayerPerTeamData: FSRadioButtons = new FSRadioButtons({
  id: 1,
  title: 'Player Per Team',
  values: [
    new SelectItem({
      id: 1,
      isActive: true,
      name: '2',
      value: '2',
    }),
    new SelectItem({
      id: 2,
      isActive: true,
      name: '3',
      value: '3',
    }),
    new SelectItem({
      id: 3,
      isActive: true,
      name: '4',
      value: '4',
    }),
  ],
});
//-----------------------------

export const MyTeamsTableColumnWidths: number[] = [
  4.2, 20.6, 29.6, 17.2, 9.4, 10.2, 10,
];

//--------------Default object values-----------
//--------------Preferences values------------
interface IPreferencesDefaults {
  readonly Mulligans: number;
  readonly MaxMulligans: number;
  readonly Gimmie: number;
  readonly TracerView: number;
  readonly PlayerView: number;
  readonly FullShotUnits: number;
  readonly PuttingUnitId: number;
  readonly SoundFx: boolean;
}

export const PreferencesDefaults: IPreferencesDefaults = {
  Mulligans: 0,
  MaxMulligans: 1000,
  Gimmie: 3,
  TracerView: 2,
  PlayerView: 2,
  FullShotUnits: 1,
  PuttingUnitId: 1,
  SoundFx: true,
};

//-------------Conditions values----------------
interface IConditionsDefaults {
  readonly PinPosition: number;
  readonly GreenSpeed: number;
  readonly GreenFirmness: number;
  readonly PuttingGrid: number;
  readonly PuttingArrow: number;
  readonly FairwayFirmness: number;
  readonly WindSetting: number;
  readonly CourseElevation: number;
}

export const ConditionsDefaults: IConditionsDefaults = {
  PinPosition: 1,
  GreenSpeed: 2,
  GreenFirmness: 2,
  PuttingGrid: 3,
  PuttingArrow: 3,
  FairwayFirmness: 2,
  WindSetting: 3,
  CourseElevation: 1,
};
